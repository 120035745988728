import React from "react";

import LogoImage from "./navigationbar.logo";
import NavigationBarNavigation from "./navigationbar.navigation";
import Link from "src/components/link";
import ImageV2 from "src/components/image/image.v2";
import { CTAButtonV2 } from "src/components/ctabutton";
import Icon, { MaterialIcon } from "src/components/icon";
import Cart from "src/components/cart";

import CurrencyPicker from "src/layouts/components/currencypicker";
import LanguagePicker from "src/layouts/components/languagepicker";

import { getSession } from "src/utils/localStorage";
import { getLanguageField } from "src/utils/language";
import { getSlug } from "src/utils/slugs";

type NavigationBarProps = {
    location: TGatsbyLocation;

    language: string;
    defaultLanguage: string;
    languages: Array<{code: string; name: string; icon: null | string}>;
    page_translations: TTranslations<{slug: string;}>;

    defaultCurrency: null | string;
    currencies: Array<{id: number; code: string; name: string;}>;

    pages: {
        account_page: null | TTranslations<{slug: string}>;
        shopping_cart_page: null | TTranslations<{slug: string}>;
    }

    has_toolbar_above: boolean;
    scrolled_down: boolean;
    navigation_bar: TNavigationBar;

    mobile_menu_open: boolean;
    onMobileMenuClick: () => void;
    onLoginButtonClick: (open: boolean) => void;
}

export default class NavigationBar extends React.Component<NavigationBarProps> {

    static defaultProps: Partial<NavigationBarProps> = {}

    static SHADOW_ON = 'shadow-[0px_10px_15px_-3px_rgba(0,_0,_0,_0.1),_0px_4px_6px_-2px_rgba(0,_0,_0,_0.05)]';
    static SHADOW_OFF = 'shadow-[0px_10px_15px_-3px_rgba(0,_0,_0,_0.0),_0px_4px_6px_-2px_rgba(0,_0,_0,_0.0)]';

    public get shadowCollapsed() {
        if (this.props.navigation_bar.shadow_when_collapsed === false) return NavigationBar.SHADOW_OFF;
        return NavigationBar.SHADOW_ON;
    }

    public get shadowNotCollapsed() {
        if (this.props.navigation_bar.shadow === false) return NavigationBar.SHADOW_OFF;
        return NavigationBar.SHADOW_ON;
    }

    public get shadow() {
        const collapsed = this.props.scrolled_down;
        if (collapsed) return this.shadowCollapsed;
        return this.shadowNotCollapsed;
    }

    public get height() {
        return this.props.scrolled_down
            ? 'h-[70px]'
            : 'h-[83px]';
    }

    public get width() {
        return this.props.navigation_bar.full_width === false
            ? 'max-w-7xl'
            : 'maw-w-full'
    }

    public get padding() {
        return this.props.navigation_bar.full_width
            ? 'px-[16px] md:px-[24px] lg:px-[40px]'
            : 'px-[16px] md:px-[24px] lg:px-[40px] xl:px-0';
    }

    public get topAlone() {
        return this.props.scrolled_down
            ? 'top-[0px]'
            : 'top-[0px]';
    }

    public get topWithToolbar() {
        return this.props.scrolled_down
            ? 'top-0 lg:top-[40px] top-with-toolbar'
            : 'top-0 lg:top-[56px] top-with-toolbar';
    }

    public get top() {
        if (this.props.has_toolbar_above) return this.topWithToolbar;
        return this.topAlone;
    }

    public get linkToHome() {
        return this.props.language === this.props.defaultLanguage
            ? '/'
            : `/${this.props.language}`
    }

    public get navigationAlignment() {
        const {navigation_items_alignment} = this.props.navigation_bar;
        if (navigation_items_alignment === 'left') return 'justify-start';
        if (navigation_items_alignment === 'center') return 'justify-center';
        if (navigation_items_alignment === 'right') return 'justify-end';
    }


    renderRightIcon(item: TNavigationRightIcon, index: number) {
        let icon = item.icon_type === 'custom'
            ? <ImageV2 className="h-6 w-6" src={item.custom_icon?.imageFile} />
            : <MaterialIcon className="h-6 w-6" icon={item.material_icon || ""} />;

        const link = item.link_type === 'external'
            ? getLanguageField(item.translations, this.props.language, 'link_href')
            : getSlug(this.props.language, this.props.defaultLanguage, item.link_page?.translations || [])

        return <Link className={"flex flex-row items-center cursor-pointer text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]"} title={""} href={link as string} key={`navrightic-${index}`}>
            {icon}
        </Link>

    }

    renderRightIcons(maxItems?: number) {
        const navbar = this.props.navigation_bar;
        let items = (navbar.navigation_bar_right_icons || []).map(row => row.item);
        if (maxItems) items = items.slice(0, maxItems);
        return items.map((item, idx) => this.renderRightIcon(item, idx));
    }

    renderMyAccount() {
        if (!this.props.navigation_bar.user_profile) return null;
        let icon = <Icon icon="UserRounded" className="w-6 h-6" />;
        if (this.props.navigation_bar.user_profile_icon_type === "material") {
            icon = <MaterialIcon className="w-6 h-6" icon={this.props.navigation_bar.user_profile_material_icon as string} />;
        }
        if (this.props.navigation_bar.user_profile_icon_type === "custom") {
            icon = <ImageV2 src={this.props.navigation_bar.user_profile_custom_icon?.imageFile} className="w-6 h-6" />
        }
        const session = getSession();
        const isUserLogged = session && session.customer;
        const accountpage = this.props.pages?.account_page?.find(translation => translation.language === this.props.language);
        if (isUserLogged) return <Link title="" href={accountpage?.slug} className="self-center"><Icon icon="UserRounded" className="w-6 h-6" /></Link>;

        return <button onClick={() => this.props.onLoginButtonClick(true)} className="text-[color:var(--text-color)] hover:text-[color:var(--hover-color)] flex">
            {icon}
        </button>;
    }

    renderCart() {
        if (!this.props.navigation_bar.shopping_cart) return null;
        let icon = <Cart/>;
        if (this.props.navigation_bar.shopping_cart_icon_type === "material") {
            icon = <MaterialIcon className="w-6 h-6" icon={this.props.navigation_bar.shopping_cart_material_icon as string} />;
        }
        if (this.props.navigation_bar.shopping_cart_icon_type === "custom") {
            icon = <ImageV2 src={this.props.navigation_bar.shopping_cart_custom_icon?.imageFile} className="w-6 h-6" />
        }
        const cart_page = this.props.pages?.shopping_cart_page?.find(translation => translation.language === this.props.language);
        return <Link title="" href={cart_page?.slug} className="text-[color:var(--text-color)] hover:text-[color:var(--hover-color)] flex">
            {icon}
        </Link>
    }

    renderCurrencyPicker() {
        if (!this.props.navigation_bar.currency_selector) return null;
        if (!this.props.defaultCurrency) return null;
        return <CurrencyPicker
            currencies={this.props.currencies}
            defaultCurrency={this.props.defaultCurrency}
            labelClassName="navBarText"
            itemsClassName="navBarItems"
        />
    }

    renderLanguagePicker() {
        const navbar = this.props.navigation_bar;
        if (!navbar.language_selector) return null;
        return <LanguagePicker
            labelClassName="navBarText"
            itemsClassName="navBarItems"
            language={this.props.language}
            languages={this.props.languages}
            page_translations={this.props.page_translations}
            language_selector_on_navigation_bar={navbar.language_selector_on_navigation_bar}
            language_selector_on_dropdown={navbar.language_selector_on_dropdown}
            language_selector_icon={navbar.language_selector_icon}
            language_flag_style={navbar.language_flag_style}
            language_selector_dropdown_chevron_icon={navbar.language_selector_dropdown_chevron_icon}
        />;
    }

    renderCTA() {
        const navigation_bar = this.props.navigation_bar;
        const call_to_action = navigation_bar.call_to_action;
        if (!call_to_action) return null;
        return <CTAButtonV2
            language={this.props.language}
            defaultLanguage={this.props.defaultLanguage}
            ctabutton={call_to_action} />
    }

    renderLogo() {
        const navbar = this.props.navigation_bar;
        const logo = navbar.logo?.imageFile;
        const mobile_logo = navbar.mobile_logo?.imageFile;
        const {linkToHome} = this;

        return <>
        {logo ? <LogoImage id="logo-desktop" link={linkToHome} logo={navbar.logo} className="hidden lg:flex" /> : null }
        {(mobile_logo || logo) ? <LogoImage id="logo-mobile" link={linkToHome} logo={navbar.mobile_logo || navbar.logo} className="flex lg:hidden" /> : null }
        </>
    }

    public get style(): any {
        return {
            '--text-color': this.props.navigation_bar.text_color?.value,
            '--hover-color': this.props.navigation_bar.hover_color?.value,
            '--menu-subitems-text-color': this.props.navigation_bar.menu_subitems_text_color?.value,
            '--link-subitems-text-color': this.props.navigation_bar.link_subitems_text_color?.value,
            '--dropdown-background': this.props.navigation_bar.dropdown_menu_background_color?.value || '#fff',
            '--dropdown-outline': this.props.navigation_bar.dropdown_menu_outline_color?.value,
            '--dropdown-text-color': this.props.navigation_bar.unselected_item_color?.value || '#000',
            '--dropdown-active-color': this.props.navigation_bar.dropdown_text_color?.value || '#000',
            '--dropdown-hover-color': this.props.navigation_bar.dropdown_hover_color?.value || '#000',
            '--background': this.props.navigation_bar.background_color?.value || '#fff',
        }
    }

    render() {
        const navbar = this.props.navigation_bar;
        const {shadow, height, width, top, padding, navigationAlignment} = this;
        const {language} = this.props;

        // show cta / 2righticons / language|currency selector on mobile:
        const hasCTA = (navbar.call_to_action);
        const mobileHasRightIcons = !hasCTA && navbar.navigation_bar_right_icons.length > 0;
        const mobileHasLangPicker = !hasCTA && !mobileHasRightIcons && navbar.language_selector === true;
        const mobileHasCurrencyPicker = !hasCTA && !mobileHasRightIcons && !mobileHasLangPicker && navbar.currency_selector === true;

        return <>
        <nav id="topbar" className={`transition-shadow sticky z-[100] flex flex-row items-center w-full ${height} ${shadow} ${top} ${padding} bg-[color:var(--background)]`} style={this.style}>
            <div className={`mx-auto flex flex-row w-full gap-0 lg:gap-8 ${width} items-center`}>
                {/* left side menu */}
                {navbar.menu_button_position_on_mobile === 'left'
                    ? <Icon icon={this.props.mobile_menu_open ? 'X' : 'Menu'} className="w-[24px] h-[24px] mr-4 lg:hidden text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]" onClick={this.props.onMobileMenuClick} />
                    : null}

                {/* left side logo */}
                {this.renderLogo()}

                {/* main navigation */}
                <div className={`flex flex-1 flex-row items-center`}>
                    <div className={`flex-1 flex-row items-center gap-8 ${navigationAlignment} max-h-[73px] hidden lg:flex`}>
                        <NavigationBarNavigation
                            language={language}
                            defaultLanguage={this.props.defaultLanguage}
                            items={navbar.navigation_items}
                            chevrons_on_menus={navbar.chevrons_on_navigation_items}
                            flyout_menu_opening={navbar.flyout_menu_opening}
                        />
                    </div>
                </div>

                {/* right side tools */}
                <div className="flex-0 flex flex-row gap-0 lg:gap-4 items-center">

                    {/* items right */}
                    <div className="flex flex-row gap-4 hidden lg:flex items-center">
                        {this.renderRightIcons()}
                        {this.renderMyAccount()}
                        {this.renderCart()}
                        {this.renderCurrencyPicker()}
                        {this.renderLanguagePicker()}
                    </div>

                    {/* items right mobile */}
                    {this.props.mobile_menu_open ? null : <div className="flex flex-row gap-4 lg:hidden">
                        {mobileHasRightIcons ? this.renderRightIcons(2) : null}
                        {mobileHasLangPicker ? this.renderLanguagePicker() : null}
                        {mobileHasCurrencyPicker ? this.renderCurrencyPicker() : null}
                    </div>}
                    {this.renderCTA()}

                    {/* mobile menu toggler */}
                    {navbar.menu_button_position_on_mobile === 'right'
                        ? <Icon icon={this.props.mobile_menu_open ? 'X' : 'Menu'} className="w-[24px] h-[24px] ml-4 lg:hidden text-[color:var(--text-color)] hover:text-[color:var(--hover-color)]" onClick={this.props.onMobileMenuClick} />
                        : null}
                </div>
            </div>
        </nav>
        </>
    }
}

